import {
  getCookie,
  isMobileWidth,
  localizedFormatMoney,
  push_enhanced_ecommerce_data,
} from '../tools';

export function recentSupportNotification($page) {
  const $endpoint = $page.find('#support-endpoint');
  const support_id = getCookie($endpoint.data('key'));
  if (!support_id) {
    return;
  }
  const endpoint_url = $endpoint.data('target');
  const intervals = [5, 10, 15, 20, 30, 30];
  const timer = ms => new Promise(res => setTimeout(res, ms));

  function showMessageBar(selector) {
    const $message_bar = $page.find('.message-bar');
    if (!$message_bar.length) {
      return;  // ProjectDetails page doesn't support message bars.
    }
    const $msg = $message_bar.find(selector);
    const hiding_class = 'd-none';

    $message_bar.attr('class', 'message-bar').addClass($msg.data('color'));
    $message_bar.find('.js-box-message').addClass(hiding_class);
    $msg.removeClass(hiding_class);
  }

  function showError(selector, error_message) {
    showMessageBar(selector);

    $.growl.error({
      title: gettext('cmn-error'),
      message: error_message,
    });
  }

  async function getStatus() {
    let finish = false;
    for (const interval of intervals) {
      if (finish) {
        break;
      }
      $.get(endpoint_url)
        .done(function(data) {
          const status = data.status;
          if (status === 'PENDING') {
            // Do nothing, let's wait some more
          } else if (status === 'PAID') {
            showMessageBar('.js-paid');
            $.growl({
              title: gettext('notif-title-ready'),
              message: gettext('cmn-support-booked'),
            });
            finish = true;
          } else if (status === 'FAILED') {
            showError('.js-failed', gettext('supwiz-failed-or-canceled-payment'),
            );
            finish = true;
          } else {
            showError('.js-error', gettext('form-err-sth-went-wrong'));
            finish = true;
          }
        })
        .fail(function() {
          showError('.js-error', gettext('form-err-sth-went-wrong'));
          finish = true;
        });
      await timer(interval * 1000);
    }
  }
  getStatus();
}

export function push_GA_details_event() {
  push_enhanced_ecommerce_data(
    'detail',
    pomagamy.ec_data,
    {},
    'Product Details',
    true,
  );
}

export function push_GA_checkout_event(step, value, description) {
  let data = {step: step,};
  if (value !== undefined) {
    data['value'] = value;
  }
  push_enhanced_ecommerce_data(
    'checkout',
    pomagamy.ec_data,
    data,
    description,
    false,
  );
}

export function push_GA_add_event() {
  push_enhanced_ecommerce_data(
    'add',
    pomagamy.ec_data,
    {},
    'Product Add To Cart',
    false,
  );
}

export function storeGAid($elem) {
  // Let's get GA clientID and pass it to backend.
  try {
    var tracker = ga.getAll()[0];
    $elem.val(tracker.get('clientId'));
  } catch (e) {
    // whatever, pass
  }
}
export function hideOddMobilePaymentChannel($form) {
  // Hide odd payment channel on mobile.
  if (isMobileWidth() && $form.find('.bank-option:not(.hidden)').length % 2) {
    $form.find('.bank-option:not(.hidden):last').addClass('hidden');
  }
}

export function initHideUserData($form) {
  // Set data hider to OFF on init by default.
  // Exception: in support restore mode keep the source value.
  // Unused now, but let's keep support restore feature for the future use
  const $hide_user_data = $form.find('#id_hide_name');
  if ($hide_user_data.is(':checked') && !isSupportRestoreMode($form)) {
    $hide_user_data.click();
  }
}

export function isSupportRestoreMode($form) {
  // Support restore mode: form is prefilled with support's values
  // Partially used now by FundacjaPomagam upsell,
  // but all Support fields are supported in js.
  return $form.hasClass('js-restore-mode');
}

export function initFacebookDataFetch() {
  // Allows user to fetch their name and email from Facebook and put them into
  // the currently visible form (online/offline).
  function isFacebookAvailable() {
    return typeof(FB) !== 'undefined';
  }

  allauth.facebook.onInit = function() {
    // Skip when FB is not loaded yet
    if (!isFacebookAvailable()) {
      return;
    }
    const user_is_registered = $('.user-data-box').data('auth') === 'True';

    FB.getLoginStatus(function(response) {
      // Don't get FB data of registered users on init.
      if (response.status === 'connected' && !user_is_registered) {
        allauth.facebook.getBasicData();
      } else {
        FB.Event.subscribe('auth.statusChange', function(response) {
          if (response.status === 'connected') {
            allauth.facebook.getBasicData();
          }
        });
      }
    });
  };
  allauth.facebook.getBasicData = function() {
    FB.api('/me?fields=id,name,email', function(response) {
      if (typeof response.error !== 'undefined') {
        return;
      }

      // Don't move it into init, user can change form after it's called.
      const is_offline = $('#payment-form-offline').is(':visible');
      const prefix = is_offline ? 'offline-' : '';
      function set_field_value(field_name, value) {
        document.getElementById('id_' + prefix + field_name).value = value;
      }

      set_field_value('facebook_uuid', response.id);
      const fields = ['name', 'email'];
      fields.forEach(function(field) {
        const value = response[field];
        if (value) {
          set_field_value(field, value);
        }
      });
    });
  };
  allauth.facebook.onLoginCanceled = function(response) {
    //pass
  };

  $('.js-fetch-fb-data').on('click', function(e) {
    e.preventDefault();

    // Skip when FB is not loaded yet
    if (!isFacebookAvailable()) {
      return;
    }

    FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        allauth.facebook.getBasicData();
      } else {
        allauth.facebook.login('', 'authenticate', 'login_skip_account');
      }
    });
  });
}

export function calculateRates(pledge_value, donation_value, $form) {
  // Updates exchange rates on international payment pages.
  const rates = window.exchange_rates;
  if (!rates.length) {
    return;
  }

  // custom pledge
  const $intro_rates = $form.find('.js-intro-rate');
  const $rate_elems = $form.find('.js-rate');
  const hidden_cls = 'hidden';
  if (pledge_value) {
    $intro_rates.addClass(hidden_cls);
    $rate_elems.removeClass(hidden_cls);
  } else {
    $intro_rates.removeClass(hidden_cls);
    $rate_elems.addClass(hidden_cls);
  }

  // payment summary
  function setRateValue(field_id_name, rate_code, raw_value) {
    $form
      .find(`${field_id_name}_${rate_code} .js-est`)
      .text(localizedFormatMoney(raw_value, 2));
  }

  let pledge_est, total_est;
  rates.forEach(rate_data => {
    pledge_est = pledge_value / rate_data.rate;
    setRateValue('#pledge', rate_data.code, pledge_est);
    total_est = (pledge_value + donation_value) / rate_data.rate;
    setRateValue('#total', rate_data.code, total_est);
  });
}

export function updateDonationValue(pledge_value, slider, $form) {
  // Sets donation value
  // from a helper (input field / slider) to a real hidden field
  // and returns said value
  const $custom_donation_helper = $form.find('#id_donation_helper');
  let donation_value = 0;
  if (!$form.is('.js-hide-donation')) {
    if ($custom_donation_helper.is(':visible')) {
      var custom_donation_helper_value = $custom_donation_helper.val();
      if (custom_donation_helper_value) {
        donation_value = parseInt(custom_donation_helper_value);
      }
    } else if (slider) {
      donation_value = (slider.noUiSlider.get() / 100) * pledge_value;
      // rounds to 2 decimal places.
      donation_value = Math.round((donation_value + Number.EPSILON) * 100) / 100;
    }
  }
  $form.find('#id_donation').val(donation_value);
  return donation_value;
}

export function updateSummaryValues(pledge_val, donation_val, $form) {
  // Updates payment summary values (pledge, donation, total)
  function setValue(selector, value, rounding) {
    $form.find(selector).text(localizedFormatMoney(value, rounding));
  }
  setValue('#checkout-pledge', pledge_val, 0);
  setValue('#checkout-community-pledge', donation_val, 2);
  setValue('#checkout-total', pledge_val + donation_val, 2);
}

export function propagatePledgeToOffline($page) {
  // Copies pledge value from online to offline form.
  const pledge_val = $page.find('#id_pledge').val();
  const $offline_pledge_el = $page.find('#id_offline-pledge');
  if (pledge_val && $offline_pledge_el.length) {
    $offline_pledge_el.val(pledge_val);
  }
}

export function loadScript(url, callback, fail_callback) {
  jQuery
    .ajax({
      url: url,
      dataType: 'script',
      async: true,
    })
    .done(function(script, textStatus) {
      if (typeof callback === 'function') {
        callback();
      }
    })
    .fail(function(jqxhr, settings, exception) {
      if (typeof fail_callback === 'function') {
        fail_callback();
      }
    });
}
