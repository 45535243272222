import { loadScript } from '../tools';
import { pulseOnLoad } from '../../input_tools';

// An external script is used to generate fingerprintId required by ZEN.
const SEON_SCRIPT_URL = 'https://cdn.deviceinf.com/js/v5/agent.js';
// An external script is used handle ZEN credit card checkout widget (popup).
const ZEN_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen.com/zen-checkout.1.0.0.js';
const ZEN_SANDBOX_CHECKOUT_SCRIPT_URL = 'https://cdn-secure.zen-test.com/zen-checkout.1.0.0.js';
const ZEN_CARD_CHANNEL_ID_ATTR = 'data-zen-card-channel-id';

export const widgetConfigParams = {
  boldFont: 'Roboto',
  regularFont: 'Roboto',
  overlayBackgroundsColor: 'rgba(0,0,0,0.5)',
  containerBackgroundColor: '#F9F9F9',
  containerBorderRadius: '10px',
  containerBorderColor: '1px solid #D3D4DC',
  containerBoxShadow: '0px 4px 34px 0px rgba(0, 0, 0, 0.25)',
  topBarBackgroundColor: '#FFFFFF',
  topBarFontColor: '#222222',
  topBarCloseIconColor: '#222222',
  inputBackgroundColor: '#FFFFFF',
  inputPrefixIconColor: '#e6e8eb',
  inputErrorColor: '#fb635f',
  inputFontColor: '#222222',
  inputPlaceholderFontColor: '#a8a8a8',
  inputBorderRadius: '5px',
  inputBorderColor: '1px solid #d3d4dc',
  inputBoxShadow: 'unset',
  buttonBackgroundColor: '#fb635f',
  buttonFontColor: '#FFFFFF',
  buttonBorderRadius: '5px',
  buttonBorderColor: 'unset',
  buttonBoxShadow: 'unset',
  fontAndIconColor: '#999999',
  statusSuccessIconColor: '#3fb77b',
  statusFailureIconColor: '#fb635f',
  statusPendingIconColor: '#3fb77b',
  statusTextColor: '#222222',
};

export function initZenPayments($form, sandbox = false) {
  /*
   * ZEN  is only available for credit card payment method.
   * Current implementation uses a popup widget provided by ZEN.
   * fingerprintId and browserDetails are still set, even though they are not required by the checkout api.
   * Fingerprints are used for fraud prevention by Pomagam backend.
   *
   */
  if ($form.hasClass('js-allow-zen-cc')) {
    loadScript(
      SEON_SCRIPT_URL,
      function() {
        initZenFields();
        initZenWidgetEvents();
      },
      onFailZen,
    );

    if ($form.is('.js-zen-sandbox-mode')) {
      loadScript(ZEN_SANDBOX_CHECKOUT_SCRIPT_URL, null, onFailZen);
    } else {
      loadScript(ZEN_CHECKOUT_SCRIPT_URL, null, onFailZen);
    }
  }
}

let scripts_failed_to_load = false;

function onFailZen() {
  // Prevent multiple calls
  if (scripts_failed_to_load) {
    return;
  }
  // Remove zen payment channel tile
  const $form = $('#payment-form');
  const channel_id = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);
  $form
    .find(`.bank-list input[value=${channel_id}]`)
    .closest('.bank-option')
    .remove();
  // Backfill one payment channel tile
  // to keep the total number of initially visible tiles
  $form
    .find('.bank-option.hidden')
    .first()
    .removeClass('hidden');
  scripts_failed_to_load = true;
}

function initZenWidgetEvents() {
  if (typeof seon === 'undefined') {
    onFailZen();
    return;
  }

  const $form = $('#payment-form');
  const $submitBtn = $form.find('#submit-btn');
  const loader_class = 'pulse-on-load';
  const ZEN_CREDIT_CARD_PAYMENT_CHANNEL = $form.attr(ZEN_CARD_CHANNEL_ID_ATTR);

  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);

    const paymentChannel = $this.find('input').attr('value');
    if (paymentChannel === ZEN_CREDIT_CARD_PAYMENT_CHANNEL) {
      $submitBtn.addClass(loader_class);
      pulseOnLoad($form);
    } else {
      // Handles the case when user chooses ZEN and switches to another channel
      // In such case, all stuff added by pulseOnLoad function needs to be removed
      if ($submitBtn.hasClass(loader_class)) {
        $submitBtn.removeClass(loader_class);
        $submitBtn.off('click.pulseOnLoad');
      }
      if ($submitBtn.parent().is('.load-button-wrapper')) {
        $submitBtn.unwrap('.load-button-wrapper');
      }
      if ($submitBtn.siblings('em.signal').length) {
        $submitBtn.siblings('em.signal').remove();
      }
    }
  });
}

function initZenFields() {
  // Double check, script may have loaded, but failed internally and not exposed seon.
  if (typeof seon === 'undefined') {
    onFailZen();
    return;
  }

  const form = document.getElementById('payment-form');

  seon.config({
    host: 'deviceinf.com',
    session_id: 'zen_' + form.getAttribute('data-session-time'),
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    onSuccess: function(message) {},
    onError: function(message) {},
  });

  seon.getBase64Session(function(data) {
    if (data) {
      document.getElementById('id_fingerprint').value = data;
    }
  });

  const browserDetails = {
    acceptHeader: decodeURIComponent(form.getAttribute('data-accept-header')),
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled(),
    lang: decodeURIComponent(form.getAttribute('data-language')),
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezone: new Date().getTimezoneOffset(),
    windowSize: '05',
    userAgent: decodeURIComponent(form.getAttribute('data-user-agent')),
  };
  document.getElementById('id_browser_details').value = JSON.stringify(
    browserDetails,
  );
}
