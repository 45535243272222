import { inputIntValue, isMobileWidth } from '../tools';
import {
  propagatePledgeToOffline,
  push_GA_checkout_event,
  storeGAid,
} from './tools';
import { removeFormErrors } from '../form_tools';
import { createGooglePayPaymentRequest } from './providers/stripe';

export function intIncreasePledgeToMinValue($form) {
  // For update pledge popups when pledge is lower then min for given channel
  $(document).on('click', '.js-change-pledge', function() {
    const new_pledge = parseInt($(this).data('value'));
    $form
      .find('#id_pledge')
      .val(new_pledge)
      .trigger('change');

    // select matching pledge preset if present
    const $pledge_preset = $form.find(
      `.js-pledge-preset[value="${new_pledge}"]`,
    );
    if ($pledge_preset.length) {
      $pledge_preset.click();
    }
    pomagamy.close_popup();
  });
}

export function initTosCheckEvent() {
  $('#id_accept_pomagam_tos, #id_offline-accept_pomagam_tos').on(
    'click',
    function() {
      push_GA_checkout_event(3, undefined, 'Accept Terms');
    },
  );
}

export function initPaymentChannelsEvents($form) {
  const mobile = isMobileWidth();
  $form.find('.bank-option > label').on('click', function(e) {
    // Due to some internal shenanigans,
    // `.bank-option` click event triggers twice
    // with different targets (input and image).
    // This is a workaround to prevent double triggering of the event.
    // Additionally, remember to DO NOT directly bind on .bank-option element
    // because it's bigger than its visual representation.
    if (e.target.nodeName === 'INPUT') {
      return;
    }

    var $this = $(this);
    $this.find('input').triggerHandler('focus.remove-errors');

    push_GA_checkout_event(
      2,
      $this.find('input').attr('value'),
      'Select payment method',
    );

    setTimeout(function() {
      var $data_box = $form.find('.user-data-box'),
        $target,
        offset;

      if (parseInt($form.find('#id_pledge').val() || 0) === 0) {
        $target = $form;
        offset = 0;
      } else if ($data_box.parent().is('.payment-box')) {
        const $summary_waypoint = $form.find('#js-summary-waypoint');
        if ($summary_waypoint.is(':visible')) {
          $target = $summary_waypoint;
          offset = mobile ? -35 : 0;
        } else {
          $target = $form.find('.section-summary');
          offset = mobile ? 30 : 0;
        }
      } else {
        // data box was moved into payment channel section
        $target = $data_box;
        offset = -20;
      }
      pomagamy.scrollToElem($target, offset);
    }, 350);
  });
}

export function initShowAllBanksEvent($form) {
  let $btn = $form.find('#show-all-banks');
  $btn.on('click', function() {
    $form.find('.bank-list.tease-gradient').removeClass('tease-gradient');
    $form.find('.bank-option').removeClass('hidden');
    $btn.remove();
  });
}

export function initFormTabsSwitchEvent($page) {
  // Ensures pledge is propagated from online to offline form on a tab switch
  const switcher_selector = '.switcher > a';
  if (!$page.find(switcher_selector).length) {
    return;
  }

  $page.on('click', switcher_selector, e => {
    e.preventDefault();
    removeFormErrors();

    const currentTabUrl = e.currentTarget.href.split('/');
    const currentTabHref = currentTabUrl[currentTabUrl.length - 1];

    if (currentTabHref === 'payment-form-offline') {
      propagatePledgeToOffline($page);
    }
  });
}

export function initOfflineSubmitEvent($page) {
  // Ensures GoogleAnalytics client ID stored in offline form's field before submission.
  const $offline_form = $page.find('#payment-form-offline');
  if (!$offline_form.length) {
    return;
  }
  const $offline_ga_client_id = $offline_form.find('#id_offline-ga_client_id');

  $offline_form.on('click', '#submit-btn-offline', e => {
    storeGAid($offline_ga_client_id);

    $(e.currentTarget)
      .parents('form.ajax:not(.processing)')
      .submit();
  });
}

export function initOnlineSubmitEvent($form) {
  const PAYPAL = 'PayPal';
  const STRIPE = 'Stripe';
  const GOOGLE_PAY = 'Google Pay';
  const CREDIT_CARD = gettext('supwiz-channel-credit-card');
  const $ga_client_id = $form.find('#id_ga_client_id');
  const $pledge = $form.find('#id_pledge');
  const allow_stripe = $form.hasClass('js-allow-stripe');
  const allow_paypal = $form.hasClass('js-allow-paypal');
  const allow_zen_cc = $form.hasClass('js-allow-zen-cc');

  $form.on('click', '#submit-btn', function() {
    storeGAid($ga_client_id);

    const channel_name = $form
      .find('input[name=payment_channel]:checked')
      .next('img')
      .attr('alt');

    const provided_by_PayPal =
      channel_name === PAYPAL ||
      (channel_name === CREDIT_CARD && !allow_stripe && !allow_zen_cc);
    // Credit Card channel is provided by PayPal if there's no Stripe nor Zen (from backend)
    const provided_by_Stripe =
      channel_name === STRIPE ||
      channel_name === GOOGLE_PAY ||
      (channel_name === CREDIT_CARD && allow_stripe && !allow_zen_cc);
    // Credit Card channel is provided by Stripe if it's available and Zen is disabled (from backend)
    const provided_by_Zen = channel_name === CREDIT_CARD && allow_zen_cc;

    function pledgeTooLow(popup_selector) {
      const $elem = $(`${popup_selector} .js-change-pledge`);
      return inputIntValue($pledge) < parseInt($elem.data('value'));
    }

    if (provided_by_PayPal && pledgeTooLow('#paypal-popup')) {
      pomagamy.show_popup('#paypal-popup');
    } else if (provided_by_Stripe && pledgeTooLow('#stripe-popup')) {
      pomagamy.show_popup('#stripe-popup');
    } else if (provided_by_Zen && pledgeTooLow('#zen-popup')) {
      pomagamy.show_popup('#zen-popup');
      if ($.fn.stopPulseLoading) {
        $.fn.stopPulseLoading();
      }
    } else if (channel_name === GOOGLE_PAY) {
      createGooglePayPaymentRequest(inputIntValue($pledge));
      $form.submit();
    } else {
      $form.submit();
    }
  });
}

export function initPledgeEvents($pledge, slider, summary_update_function) {
  $pledge.on('keyup change', function() {
    // fake slider update to rerender its label
    if ($(slider).is(':visible')) {
      slider.noUiSlider.set(slider.noUiSlider.get());
    }
    summary_update_function();
  });

  $pledge.siblings('i.currency').on('click', function() {
    $pledge.focus();
  });
}

export function initCustomDonationHelperEvents(
  $form,
  summary_update_function,
  on_blur_function,
) {
  $form
    .find('#id_donation_helper')
    .on('keyup', function() {
      summary_update_function();
    })
    .on('blur', function() {
      // Triggers when focus is lost on the custom donation input
      on_blur_function($(this).val() === '0');
    });
}
